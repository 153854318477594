module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/circleci/project/src/layouts/MainLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"className":"header-link-icon","removeAccents":true}}],"rehypePlugins":[null],"globalScope":"\n          import * as Elements from \"@artsy/palette\"\n          import { ColorComponent } from \"components/ColorComponent\"\n          import { Table } from \"components/Table\"\n          import { CodeEditor, Playground  } from \"components/CodeEditor\"\n          import { Toggle as Toggler, State } from 'react-powerplug'\n          export default {\n            CodeEditor,\n            ColorComponent,\n            Playground,\n            Toggler,\n            State,\n            Table,\n            ...Elements,\n          }\n        "},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
