
          import * as Elements from "@artsy/palette"
          import { ColorComponent } from "components/ColorComponent"
          import { Table } from "components/Table"
          import { CodeEditor, Playground  } from "components/CodeEditor"
          import { Toggle as Toggler, State } from 'react-powerplug'
          export default {
            CodeEditor,
            ColorComponent,
            Playground,
            Toggler,
            State,
            Table,
            ...Elements,
          }
        